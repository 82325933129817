import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, PipelineStepModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'pipelineSteps/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/pipeline-info/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'pipelineSteps/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ pipeline_id: string }>, any>) => {
    const endpoint = buildUrl(`/pipeline-info`, payload?.params);
    const response = await api.caller.get<Response<Pagination<PipelineStepModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchManyByPipeline = createAsyncThunk(
  'pipelineSteps/getManyByPipeline',
  createAsyncAction(async (payload: ActionParams<{ id: string; queries?: Queries }, any>) => {
    const endpoint = buildUrl(`/pipeline-info/${payload.params?.id}`, payload.params?.queries);
    const response = await api.caller.get<Response<Pagination<PipelineStepModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const trigger = createAsyncThunk(
  'pipelineSteps/trigger',
  createAsyncAction(async (payload: ActionParams<{ pipelineId: string }, any>) => {
    const endpoint = `/pipeline-info/${payload.params?.pipelineId}`;
    const response = await api.caller.post<Response<{ id: string }>>(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'pipelineSteps/create',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/pipeline-info`;
    const response = await api.caller.post<Response<{ id: string }>>(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'pipelineSteps/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/pipeline-info/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'pipelineSteps/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/pipeline-info/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'pipelineSteps/removeMany',
  createAsyncAction(async (payload: ActionParams<{ ids: string[] }>) => {
    const endpoint = `/pipeline-info?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
