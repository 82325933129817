import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { userSelector } from '@store';

export const useCheckAuth = () => {
  const user = useSelector(userSelector.selectUserData);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate('/auth');
    else if (user.password_change === false && location.pathname !== '/change-password') {
      navigate('/change-password');
    }
  }, [user]);
};
