import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftSvg } from '@assets/svgs';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.primary};
  cursor: pointer;
`;

const Arrow = styled(ChevronLeftSvg)`
  margin-right: 15px;
`;

export const Back: React.FC = ({ ...props }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <Wrapper {...props} onClick={onClick}>
      <Arrow />
      Back
    </Wrapper>
  );
};
