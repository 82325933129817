import React from 'react';
import { BreadcrumbProps } from 'antd';
import Item, { BreadcrumbItemProps } from 'antd/lib/breadcrumb/BreadcrumbItem';
import { Link } from 'react-router-dom';

import * as S from './styles';

// export const BreadcrumbItem = S.BreadcrumbItem;

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, ...props }) => <S.Breadcrumb {...props}>{children}</S.Breadcrumb>;

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ href, children, ...props }) => <Item {...props}>{href ? <Link to={href}>{children}</Link> : children}</Item>;
