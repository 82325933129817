import { Button, Form, FormInstance, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { userActions, userSelector } from '@store';

import { passwordRules, cofirmPasswordRules } from './rules';

export type ChangePasswordFormType = {
  password: string;
  new_password: string;
};

type Props = {
  form?: FormInstance<ChangePasswordFormType>;
  button?: { visible?: boolean; block?: boolean };
  handle: (npw: string) => Promise<void>;
};

export const ChangePasswordContent: React.FC<Props> = ({ handle, button, form }) => {
  const [formModal] = Form.useForm<ChangePasswordFormType>();
  const transactionChange = useSelector(userSelector.selectChangePassword);
  const transactionLogin = useSelector(userSelector.selectLoginEmail);
  const dispatch = useDispatch<AppDispatch>();
  const loading = transactionChange.loading || transactionLogin.loading;

  const onFinish = async (data: ChangePasswordFormType) => {
    await new Promise((res) => dispatch(userActions.changePassword({ params: data, onSuccess: res })));
    await handle(data.new_password);
  };

  return (
    <Form form={form || formModal} name="change-password" initialValues={{}} onFinish={onFinish} layout="vertical" disabled={loading}>
      <Form.Item name="password" className="material-input" label="Password" validateFirst rules={[{ required: true, message: 'Password is required' }]}>
        <Input id="password" placeholder="Password" type="password" autoFocus maxLength={50} />
      </Form.Item>
      <Form.Item name="new_password" className="material-input" label="New Password" validateFirst rules={passwordRules}>
        <Input id="new_password" placeholder="Password" type="password" autoFocus maxLength={50} />
      </Form.Item>
      <Form.Item name="new_password_confirm" className="material-input" label="Confirm Password" validateFirst rules={cofirmPasswordRules}>
        <Input id="new_password_confirm" placeholder="Confirm Password" type="password" autoFocus maxLength={50} />
      </Form.Item>
      {button?.visible !== false && (
        <Button type="primary" htmlType="submit" loading={loading} block={button?.block || true}>
          Change password
        </Button>
      )}
    </Form>
  );
};
