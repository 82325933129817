import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { ActionParams } from '@types';
import { createAsyncAction } from '@helpers/thunkWrapper';

import { actions } from './slice';

export const reset = createAsyncThunk(
  'upload/reset',
  createAsyncAction(async (payload: ActionParams<any, any>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const upload = state.upload;

    Object.keys(upload).map((id) => {
      const uploadProgress = upload[id];
      if (uploadProgress.isUploadDone) return;
      uploadProgress.stop();
      dispatch(actions.uploadProgress({ id, progress: { isStop: true } }));
      notification.close(`media_upload_${id}`);
    });
  })
);
