import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, Pagination, Queries, Response, DeviceModel, ClassificationType } from '@types';

export const fetch = createAsyncThunk(
  'devices/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/devices/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'devices/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id: string }>, any>) => {
    const endpoint = buildUrl(`/devices`, payload?.params);
    const response = await api.caller.get<Response<Pagination<DeviceModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'devices/create',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/devices`;
    const response = await api.caller.post<Response<{ id: string }>>(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'devices/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/devices/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const updateResult = createAsyncThunk(
  'devices/update-result',
  createAsyncAction(async (payload: ActionParams<{ id: string; result: ClassificationType }, any>) => {
    const endpoint = `/devices/${payload.params?.id}/result`;
    const response = await api.caller.put(endpoint, {
      result: payload.params?.result,
    });
    const data = response.data;

    return data;
  })
);

export const deploy = createAsyncThunk(
  'devices/deploy',
  createAsyncAction(async (payload: ActionParams<{ id: string; pipeline_id: string; jenkin_device_id: string; jenkin_run_id: string }, any>) => {
    const endpoint = `/devices/deploy/${payload.params?.id}`;
    const response = await api.caller.put(endpoint, { pipeline_id: payload.params?.pipeline_id, jenkin_device_id: payload.params?.jenkin_device_id, jenkin_run_id: payload.params?.jenkin_run_id });
    const data = response.data;

    return data;
  })
);

export const undeploy = createAsyncThunk(
  'devices/undeploy',
  createAsyncAction(async (payload: ActionParams<{ id: string; pipeline_id?: string }, any>) => {
    const endpoint = `/devices/undeploy/${payload.params?.id}`;
    const response = await api.caller.put(endpoint);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'devices/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/devices/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'devices/removeMany',
  createAsyncAction(async (payload: ActionParams<{ ids: string[] }>) => {
    const endpoint = `/devices?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
