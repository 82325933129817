import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, PipelineModel, Pagination, Queries, Response } from '@types';

import { ParamsCreate } from './type';

export const fetch = createAsyncThunk(
  'pipelines/get',
  createAsyncAction(async (payload: ActionParams<{ id: string }, any>) => {
    const endpoint = `/pipelines/${payload.params?.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'pipelines/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id: string }>, any>) => {
    const endpoint = buildUrl(`/pipelines`, payload?.params);
    const response = await api.caller.get<Response<Pagination<PipelineModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'pipelines/create',
  createAsyncAction(async (payload: ActionParams<ParamsCreate, any>) => {
    const endpoint = `/pipelines`;
    const response = await api.caller.post<Response<{ id: string }>>(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'pipelines/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/pipelines/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'pipelines/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/pipelines/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'pipelines/removeMany',
  createAsyncAction(async (payload: ActionParams<{ ids: string[] }>) => {
    const endpoint = `/pipelines?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
