import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { buildUrl } from '@helpers/utils';
import { ActionParams, Response, UserModel } from '@types';
import { graphConfig, loginRequest } from '@constants';
import { createAsyncAction } from '@helpers/thunkWrapper';

import { actions } from './slice';

const callMsGraph = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'GET',
    headers: headers,
  };
  return (
    fetch(graphConfig.graphMeEndpoint, options)
      .then((response) => response.json())
      // eslint-disable-next-line
      .catch((error) => console.log(error))
  );
};

export const fetchUsers = createAsyncThunk(
  'users/get_users',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl('/users', payload.params);
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const searchUsers = createAsyncThunk(
  'users/search_users',
  createAsyncAction(async (payload: ActionParams<{ value: string; size?: number; skip?: number }, any>) => {
    const endpoint = buildUrl(`/search`, { type: 'user', ...payload.params });
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const getInfo = createAsyncThunk(
  'users/info',
  createAsyncAction(async (payload, { dispatch }) => {
    const endpoint = '/users/info';
    const response = await api.caller.get(endpoint);
    const { data } = response.data.data;

    dispatch(actions.setUser(data));

    return data;
  })
);

export const doLogin = createAsyncThunk(
  'users/login',
  createAsyncAction(async (payload: ActionParams<any, any>, { dispatch }) => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: payload.params.accounts[0],
    };

    const accessTokenResponse: any = await payload.params.instance.acquireTokenSilent(accessTokenRequest);
    const response = await callMsGraph(accessTokenResponse.accessToken);

    const responseUserData = await api.caller.post<Response<{ data: UserModel; token: string }>>('/users', {
      id: response.id,
      email: response.mail,
      name: response.displayName,
    });
    const { data, token } = responseUserData.data.data;

    dispatch(actions.setUser(data));
    api.setToken(token);

    return responseUserData.data;
  })
);

export const doLoginEmail = createAsyncThunk(
  'users/doLoginEmail',
  createAsyncAction(async (payload: ActionParams<{ email: string; password: string }, any>, { dispatch }) => {
    const endpoint = '/users/sign_in';
    const response = await api.caller.post<Response<{ data: UserModel; token: string }>>(endpoint, payload.params);

    const { data, token } = response.data.data;
    dispatch(actions.setUser(data));
    api.setToken(token);

    return response.data;
  })
);

export const doLoginGoogle = createAsyncThunk(
  'users/loginGoogle',
  createAsyncAction(async (payload: ActionParams<{ code: string }, any>, { dispatch }) => {
    const endpoint = '/users/sign_in_google';
    const response = await api.caller.post<Response<{ data: UserModel; token: string }>>(endpoint, { auth_code: payload.params });

    const { data, token } = response.data.data;
    dispatch(actions.setUser(data));
    api.setToken(token);

    return response.data;
  })
);

export const changePassword = createAsyncThunk(
  'users/changePassword',
  createAsyncAction(async (payload: ActionParams<{ password: string; new_password: string }, any>) => {
    const endpoint = '/users/password';
    const response = await api.caller.put<Response<any>>(endpoint, payload.params);

    return response.data;
  })
);
