import { Empty as UIEmpty, EmptyProps, Spin } from 'antd';

import { LoadingContainer } from './Loading';

export const Empty: React.FC<EmptyProps & { loading?: boolean }> = ({ loading, ...props }) => (
  <UIEmpty
    {...props}
    image={
      loading ? (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      ) : (
        props.image
      )
    }
    description={loading ? '' : props.description}
  />
);
