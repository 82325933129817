import React, { PropsWithChildren } from 'react';
import { Modal, ModalProps } from 'antd';

export type ModalConfirmProps = PropsWithChildren<ModalProps> & { setVisible: (value: boolean) => void; onClickOk: () => Promise<unknown> | unknown; title: string };

export const ModalConfirm: React.FC<ModalConfirmProps> = (props) => {
  const onInvisible = () => props.setVisible(false);

  const onOk = async () => {
    await props.onClickOk();
    onInvisible();
  };

  const onCancel = () => onInvisible();

  return (
    <Modal {...props} title={props.title} centered onCancel={props.onCancel || onCancel} onOk={onOk}>
      {props.children}
    </Modal>
  );
};
