import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// auth pages: no lazy loading for auth pages to avoid flickering
import LoginPage from '@pages/Auth/LoginPage';
// layouts
import AdminLayout from '@layouts/AdminLayout';
import InfoLayout from '@layouts/InfoLayout';
import AppLayout from '@layouts/AppLayout';

import { withLoading } from './withLoading.hoc';

const AuthLayout = React.lazy(() => import('@layouts/AuthLayout'));

// application pages
const Home = React.lazy(() => import('@pages/Home/Home'));

// admin pages
const WorkflowPage = React.lazy(() => import('@pages/admin/Workflow/WorkflowPage'));
const Members = React.lazy(() => import('@pages/admin/Members'));
const OverviewPage = React.lazy(() => import('@pages/admin/Overview'));

// project pages
const ProjectFormPage = React.lazy(() => import('@pages/admin/Projects/ProjectFormPage'));
const ProjectListPage = React.lazy(() => import('@pages/admin/Projects/components/ProjectListPage'));
const ProjectDetailPage = React.lazy(() => import('@pages/admin/Projects/ProjectDetailPage'));
const ProjectUserFormPage = React.lazy(() => import('@pages/admin/Projects/ProjectUserFormPage'));

// extra pages
const ChangePasswordPage = React.lazy(() => import('@pages/admin/ChangePassword'));

// dataset pages
const DatasetsOverviewPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsOverviewPage'));
const DatasetsDetailPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsDetailPage'));
const DatasetsListPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsListPage'));
// const DatasetsFeaturePage = React.lazy(() => import('@pages/admin/Datasets/DatasetsFeaturePage'));
const DatasetsSoundLibrariesPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsSoundLibrariesPage'));
const DatasetsLabelingPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsLabelingPage'));
const DatasetsExportedPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsExportedPage'));
const DatasetsExportedDetailPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsExportedDetailPage'));

// sample check
const SampleCheckPage = React.lazy(() => import('@pages/admin/SampleCheck/SampleCheckPage'));
const SampleCheckHistoryPage = React.lazy(() => import('@pages/admin/SampleCheck/SampleCheckHistoryPage'));

const Error404Page = React.lazy(() => import('@pages/Errors/Error404Page'));
const Logout = React.lazy(() => import('./Logout'));

const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC<PropsWithChildren> = ({ children }) => (
  <BrowserRouter>
    <Routes>
      <Route path="/workspace" element={<AdminLayout>{children}</AdminLayout>}>
        <Route index element={<WorkflowPage />} />
        <Route path="workflow" element={<WorkflowPage />} />
        <Route path="members" element={<Members />} />
        <Route path="overview" element={<OverviewPage />} />

        <Route path="data">
          <Route index element={<DatasetsOverviewPage />} />
          <Route path=":datasetId" element={<DatasetsDetailPage />} />
          <Route path="overview" element={<DatasetsOverviewPage />} />
          <Route path="media" element={<DatasetsListPage />} />
          <Route path="sound-libraries" element={<DatasetsSoundLibrariesPage />} />
          <Route path="label" element={<DatasetsLabelingPage />} />
          <Route path="exported-dataset" element={<DatasetsExportedPage />} />
          <Route path="exported-dataset/:datasetId" element={<DatasetsExportedDetailPage />} />
          {/* <Route path="features" element={<DatasetsFeaturePage />} /> */}
        </Route>

        <Route path="sample-check">
          <Route index element={<SampleCheckPage />} />
          <Route path="check" element={<SampleCheckPage />} />
          <Route path="history" element={<SampleCheckHistoryPage />} />
        </Route>
      </Route>

      <Route path="/project" element={<InfoLayout>{children}</InfoLayout>}>
        <Route index element={<ProjectListPage />} />
        <Route path="list" element={<ProjectListPage />} />
        <Route path="add" element={<ProjectFormPage />} />
        <Route path="detail/:projectId" element={<ProjectDetailPage />} />
        <Route path=":projectId/user/add" element={<ProjectUserFormPage />} />
        <Route path=":projectId/user/edit/:userId" element={<ProjectUserFormPage />} />
      </Route>

      <Route path="/change-password" element={<InfoLayout>{children}</InfoLayout>}>
        <Route index element={<ChangePasswordPage />} />
      </Route>

      <Route path="/auth" element={<AuthLayoutFallback />}>
        <Route index element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path={'/'} element={<AppLayout>{children}</AppLayout>}>
        <Route index element={<Home />} />
      </Route>

      <Route path="404" element={<Error404 />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/logout" element={<LogoutFallback />} />
    </Routes>
  </BrowserRouter>
);
