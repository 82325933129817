import { Suspense, useEffect, useMemo, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import viVN from 'antd/lib/locale/vi_VN';
import enUS from 'antd/lib/locale/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { EventCallbackFunction } from '@azure/msal-browser';

import { AppRouter } from '@router';
import { GlobalStyle, darkTheme, lightTheme } from '@assets/styles';
import { useAppNotiUpload, useLanguage } from '@helpers/hooks';
import { UILoading, ThemeSwitcher } from '@components';
import { themeSelector, userActions, userSelector } from '@store';

export default function App() {
  // eslint-disable-next-line
  // console.log('env', process.env);
  useAppNotiUpload();
  const { instance } = useMsal();
  const theme = useSelector(themeSelector.selectTheme);
  const user = useSelector(userSelector.selectUserData);
  const [loading, setLoading] = useState(true);
  const currentTheme = useMemo(() => (theme === 'dark' ? darkTheme : lightTheme), [theme]);
  const { language } = useLanguage();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('AIC_TOKEN') && !user) {
        try {
          await new Promise((res) => dispatch(userActions.getInfo({ params: undefined, onEnd: res })));
        } catch (error) {}
      }

      setLoading(false);
    })();
  }, [user]);

  useEffect(() => {
    const callback: EventCallbackFunction = (params) => {
      if (params.error) instance.logout();
      if (params.error && loading) setLoading(false);
    };
    const eventId = instance.addEventCallback(callback);
    if (eventId) return () => instance.removeEventCallback(eventId);
  }, [instance]);

  if (loading) return <UILoading />;

  return (
    <Suspense
      fallback={
        <div className="loading-app">
          <UILoading />
        </div>
      }
    >
      <meta name="theme-color" content={currentTheme.colors.main.primary} />
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <ConfigProvider locale={language === 'en' ? enUS : viVN}>
          <div className="App">
            <ThemeSwitcher theme={theme}>
              <AppRouter />
            </ThemeSwitcher>
          </div>
        </ConfigProvider>
      </ThemeProvider>
    </Suspense>
  );
}
