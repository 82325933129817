import React from 'react';
import styled from 'styled-components';

import { GlobalSpinner } from './GlobalSpinner';

type LoadingProps = {
  size?: string;
  color?: string;
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const Loading: React.FC<LoadingProps> = ({ size, color }) => {
  const spinnerColor = color || 'red';

  return (
    <SpinnerContainer>
      <GlobalSpinner size={size} color={spinnerColor} />
    </SpinnerContainer>
  );
};
