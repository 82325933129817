import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';

export const emailTest = createAsyncThunk(
  '/email/test',
  createAsyncAction(async () => {
    const endpoint = '/email/test';
    const response = await api.caller.post(endpoint);
    const data = response.data;

    return data;
  })
);
