import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { buildUrl } from '@helpers/utils';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { ActionParams } from '@types';

export const getLocation = createAsyncThunk(
  'locations/get_location',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/locations/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const getLocations = createAsyncThunk(
  'locations/get_locations',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`/locations`, payload.params);
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const createLocation = createAsyncThunk(
  'locations/create_location',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/locations`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const updateLocation = createAsyncThunk(
  'locations/update_location',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/locations/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const deleteLocation = createAsyncThunk(
  'locations/delete_location',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/locations/${payload.params.id}`;
    const response = await api.caller.delete(endpoint, payload.params.id);
    const data = response.data;

    return data;
  })
);
