import { createRef, MutableRefObject } from 'react';

const refAudioPlaying = createRef<HTMLAudioElement>() as MutableRefObject<HTMLAudioElement | undefined>;
export const useAudio = () => {
  const pause = () => {
    if (refAudioPlaying.current && refAudioPlaying.current.pause) refAudioPlaying.current.pause();
    refAudioPlaying.current = undefined;
  };

  const release = (element?: HTMLAudioElement) => {
    if (refAudioPlaying.current === element) refAudioPlaying.current = undefined;
  };

  const play = (audio: HTMLAudioElement) => {
    pause();
    refAudioPlaying.current = audio;
  };

  return {
    pause,
    play,
    release,
  };
};
