import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { ActionParams } from '@types';

export const getDataGovernance = createAsyncThunk(
  'extra/getDataGovernance',
  createAsyncAction(async (payload: ActionParams<{ projectId: string }, any>) => {
    const endpoint = `/data_governance?project_id=${payload.params?.projectId}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
