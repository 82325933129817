import { Button, Card, Empty, Input, Radio, Space, Spin } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelect, UseSelectFetch } from '@helpers/hooks';

const Wrapper = styled(Card)`
  .ant-card-head,
  .ant-card-actions > li {
    margin: 0;
    padding: 8px;
  }

  .ant-card-body,
  .ant-card-head-title {
    padding: 0;
  }
`;

const WrapActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapContent = styled(Radio.Group)`
  width: 100%;
  padding: 8px 0;

  .ant-space {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

    &-item {
      padding: 0 8px;

      & > .ant-radio-wrapper span:last-child {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

const WrapLoading = styled.div`
  display: flex;
  justify-content: center;
`;

type Props<RecordType> = FilterDropdownProps & {
  placeholder: string;
  fetch: UseSelectFetch<RecordType>;
};

export const FilterDropdown = <RecordType extends object>({ confirm, selectedKeys, setSelectedKeys, visible, fetch, placeholder }: Props<RecordType>) => {
  const [searchText, setSearchText] = useState('');

  const datasetHook = useSelect<RecordType>({ fetch });

  const onClickReset = () => {
    setSearchText('');
    setSelectedKeys([]);
    datasetHook.onSearch('');
  };

  const onClickOk = () => {
    confirm();
  };

  useEffect(() => {
    if (!datasetHook.options.length) datasetHook.fetchData(true);
  }, [visible]);

  return (
    <Wrapper
      title={
        <Input
          placeholder={placeholder}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
            datasetHook.onSearch(event.target.value);
          }}
        />
      }
      actions={[
        <WrapActions>
          <Button size="small" type="text" onClick={onClickReset}>
            Reset
          </Button>
          <Button size="small" type="primary" onClick={onClickOk}>
            OK
          </Button>
        </WrapActions>,
      ]}
    >
      <WrapContent value={selectedKeys[0]} onChange={(e) => setSelectedKeys([e.target.value])}>
        {datasetHook.loading ? (
          <WrapLoading>
            <Spin />
          </WrapLoading>
        ) : datasetHook.options.length ? (
          <Space direction="vertical" onScroll={datasetHook.onPopupScroll}>
            {datasetHook.options.map((o) => (
              <Radio value={o.value} key={o.value}>
                {o.label}
              </Radio>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </WrapContent>
    </Wrapper>
  );
};
