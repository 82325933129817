import { Button } from 'antd';
import styled from 'styled-components';

export const WrapActions = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`;

export const WrapList = styled.ul`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ItemList = styled.li`
  list-style: none;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  & > .ant-select {
    width: 100%;
  }

  && .ant-select-selection-search {
    left: 0px;
    right: 0px;
    padding-left: 11px;
  }
`;

export const BtnDelete = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0 7px;
  background-color: ${({ theme }) => theme.colors.main.error};

  svg {
    height: 15px;
    pointer-events: none;
  }
  svg path {
    fill: white;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.main.disable};
    border: 1px solid #d9d9d9;

    svg path {
      fill: #d9d9d9;
    }
  }
`;

export const ButtonAdd = styled(Button)`
  svg path {
    fill: #000000d9;
  }

  &:hover,
  &:focus,
  &:active {
    svg path {
      fill: ${({ theme }) => theme.colors.main.primary};
    }
  }
`;

export const TextDescription = styled.p`
  margin-bottom: 10px;
`;
