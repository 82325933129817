import { Suspense } from 'react';

import { Loading } from '@components';

// eslint-disable-next-line @typescript-eslint/ban-types
export const withLoading =
  (Component: any): any =>
  (props: any) =>
    (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
