import styled from 'styled-components';
import { Breadcrumb as AntBreadcrumb } from 'antd';
export const { Item } = AntBreadcrumb;

export const BreadcrumbItem = styled(Item)``;
export const Breadcrumb = styled(AntBreadcrumb)`
  margin-bottom: 1rem;
  li {
    a {
      color: ${({ theme }) => theme.colors.main.primary};
    }
    &:last-child {
      a {
        color: ${({ theme }) => theme.colors.text.main};
      }
    }
  }
`;
