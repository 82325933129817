import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: calc(100vh - 130px);
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    min-height: calc(100vh - 155px);
  }
`;

export const Loading: React.FC = () => (
  <Wrapper>
    <Spin />
  </Wrapper>
);
