import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, ExportedDatasetModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'exported-datasets/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/export_dataset/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'exported-datasets/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id?: string }>, any>) => {
    const endpoint = buildUrl(`/export_dataset`, payload?.params);
    const response = await api.caller.get<Response<Pagination<ExportedDatasetModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchManyDone = createAsyncThunk(
  'exported-datasets/getManyDone',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id?: string }>, any>) => {
    const endpoint = buildUrl(`/export_dataset/done`, payload?.params);
    const response = await api.caller.get<Response<Pagination<ExportedDatasetModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'exported-datasets/create',
  createAsyncAction(async (payload: ActionParams<{ name: string; dataset_ids: string[]; project_id: string; machine_type: string; train_size: number }, any>) => {
    const endpoint = `/export_dataset`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const exportAsZIP = createAsyncThunk(
  'exported-datasets/exportAsZIP',
  createAsyncAction(async (payload: ActionParams<{ id: string }, any>) => {
    const endpoint = `/export_dataset/zip/${payload.params?.id}`;
    const response = await api.caller.post(endpoint);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'exported-datasets/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/export_dataset/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'exported-datasets/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/export_dataset/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'exported-datasets/removeMany',
  createAsyncAction(async (payload: ActionParams<{ project_id: string; ids: string[] }>) => {
    const endpoint = `/export_dataset?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
