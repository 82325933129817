import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.project;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),
  selectList: createSelector(selectSelf, (state) => state.list),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectProjectId: createSelector(selectSelf, (state) => state.item.result?.id),
  selectProjectIsSample: createSelector(selectSelf, (state) => state.item.result?.id === 'sample'),
};
