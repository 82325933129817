import React, { PropsWithChildren, useEffect } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { uploadActions } from '@store';
import { useCheckAuth } from '@helpers/hooks';

import { MainLayout } from '../MainLayout';

import { LayoutMaster } from './styles';

const { Content } = Layout;

export const InfoLayout: React.FC<PropsWithChildren> = () => {
  useCheckAuth();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(uploadActions.reset({}));
  }, []);

  return (
    <MainLayout>
      <LayoutMaster>
        <Layout className="cra-main-layout">
          <Layout className="cra-main-layout-content">
            <Content className="cra-content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </LayoutMaster>
    </MainLayout>
  );
};
