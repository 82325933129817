import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { appActions, appSelector, projectSelector, roleSelector } from '@store';

import { sidebarNavigationConfig } from './config';
import { SidebarNavigationItem, SiderContentProps } from './type';
import { TitleProject, Wrapper, WrapperAction, WrapperMenu } from './styles';

const sidebarNavFlat = sidebarNavigationConfig.reduce((result: SidebarNavigationItem[], current) => result.concat(current.children && current.children.length > 0 ? current.children : current), []);

export const SiderMenu: React.FC<SiderContentProps> = ({ collapsible }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const role = useSelector(roleSelector.selectUserRole);
  const project = useSelector(projectSelector.selectItem);
  const isSample = useSelector(projectSelector.selectProjectIsSample);
  const collapsed = useSelector(appSelector.selectSidebarCollapsed);
  const sidebarCollapsed = useSelector(appSelector.selectSidebarCollapsed);
  const [sidebars, setSidebars] = useState<SidebarNavigationItem[]>([]);

  const changeCollapsed = () => dispatch(appActions.setCollapsed(!collapsed));

  const currentMenuItem = sidebarNavFlat.find(({ url }) => (url ? location.pathname.includes(url) : false));
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigationConfig.find(({ children }) => children?.some(({ url }) => (url ? location.pathname.includes(url) : false)));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  useEffect(() => {
    const sidebarItems = role.result?.role !== 'admin' && !isSample ? sidebarNavigationConfig.filter((o) => ['/workspace/workflow', '/workspace/data'].some((oo) => oo === o.key)) : sidebarNavigationConfig;
    setSidebars(sidebarItems);
  }, [role]);

  return (
    <Wrapper>
      <TitleProject collapsed={sidebarCollapsed}>{project.result?.name}</TitleProject>
      <WrapperMenu>
        <Menu mode="inline" selectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys}>
          {sidebars.map((nav) =>
            nav.children && nav.children.length > 0 ? (
              <Menu.SubMenu key={nav.key} title={t(nav.title)} icon={nav.icon} popupClassName="d-none">
                {nav.children.map((childNav) => (
                  <Menu.Item key={childNav.key} title="">
                    <Link to={childNav.url || ''}>{t(childNav.title)}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={nav.key} title="" icon={nav.icon}>
                <Link to={nav.url || ''}>{t(nav.title)}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </WrapperMenu>
      {collapsible !== false && (
        <WrapperAction>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: changeCollapsed,
          })}
        </WrapperAction>
      )}
    </Wrapper>
  );
};
