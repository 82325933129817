import styled from 'styled-components';

import { LogoSvg } from '@assets/svgs';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  color: #00000099;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  margin-left: 8px;
`;

export const Logo: React.FC = () => (
  <Wrapper>
    <LogoSvg />
    <Text>SoundAI</Text>
  </Wrapper>
);
