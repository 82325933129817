import styled from 'styled-components';
import { Alert, Typography } from 'antd';

import { PipelineStepStatus } from '@types';

export const DetailPack = {
  Container: styled.div`
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  `,

  Line: styled.div`
    display: flex;
  `,

  TextTitle: styled.p`
    font-size: 1.14rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #c2185b;
  `,

  TextCategory: styled.p`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.1px;
    color: black;
    width: 135px;
    margin-bottom: 0;
  `,

  TextDetail: styled(Typography.Paragraph)<{ status?: PipelineStepStatus }>`
    flex: 1;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.25px;
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: ${({ status, theme }) => (status === 'Cancelled' ? theme.colors.main.warning : status === 'Done' ? theme.colors.main.primary : status === 'Failed' ? theme.colors.main.error : status === 'Running' ? theme.colors.main.info : status === 'Not started' ? theme.colors.text.main : theme.colors.text.main)};
  `,

  WrapError: styled(Alert)`
    /* max-height: 100px; */
    /* border-radius: 4px; */
    /* background-color: #000; */
    /* overflow-y: scroll; */
    /* padding: 8px; */
    /* font-weight: 500; */
    /* color: #fff; */
    /* color: rgb(163, 32, 21); */
  `,
};
