import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserModel } from '@types';

import { doLogin, doLoginEmail, doLoginGoogle, changePassword, fetchUsers, getInfo, searchUsers } from './action';
import { State } from './type';

const initialState: State = {
  getInfo: {
    loading: false,
  },
  login: {
    loading: false,
    result: {},
  },
  logout: {
    loading: false,
    result: {},
  },
  loginGoogle: {
    loading: false,
    result: {},
  },
  loginEmail: {
    loading: false,
    result: {},
  },
  forgotpassword: {
    loading: false,
    result: {},
  },
  changePassword: {
    loading: false,
  },
  edit: {
    loading: false,
    result: {},
  },
  verify: {
    loading: false,
    result: {},
  },

  list: {
    loading: false,
    result: {},
  },
  item: {
    loading: false,
    result: {},
  },
  search: {
    loading: false,
  },
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state, payload: PayloadAction<UserModel | undefined>) => {
      state.user = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.list.loading = true;
      state.list.result = {};
      state.list.error = {};
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = {};
    });
    builder.addCase(searchUsers.pending, (state) => {
      state.search.loading = true;
      state.search.error = {};
    });
    builder.addCase(searchUsers.fulfilled, (state) => {
      state.search.loading = false;
      state.search.error = {};
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      state.search.loading = false;
      state.search.error = action.error;
    });
    builder.addCase(getInfo.pending, (state) => {
      state.getInfo.loading = true;
      state.getInfo.error = {};
    });
    builder.addCase(getInfo.fulfilled, (state) => {
      state.getInfo.loading = false;
      state.getInfo.error = {};
    });
    builder.addCase(getInfo.rejected, (state, action) => {
      state.getInfo.loading = false;
      state.getInfo.error = action.error;
    });
    builder.addCase(doLogin.pending, (state) => {
      state.login.loading = true;
      state.login.result = {};
      state.login.error = {};
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.login.loading = false;
      state.login.result = action.payload;
      state.login.error = {};
    });
    builder.addCase(doLogin.rejected, (state, action) => {
      state.login.loading = false;
      state.login.result = {};
      state.login.error = action.payload as any;
    });
    builder.addCase(doLoginGoogle.pending, (state) => {
      state.loginGoogle.loading = true;
      state.loginGoogle.result = {};
      state.loginGoogle.error = {};
    });
    builder.addCase(doLoginGoogle.fulfilled, (state, action) => {
      state.loginGoogle.loading = false;
      state.loginGoogle.result = action.payload;
      state.loginGoogle.error = {};
    });
    builder.addCase(doLoginGoogle.rejected, (state, action) => {
      state.loginGoogle.loading = false;
      state.loginGoogle.error = action.error;
      state.loginGoogle.result = {};
    });
    builder.addCase(doLoginEmail.pending, (state) => {
      state.loginEmail.loading = true;
      state.loginEmail.result = {};
      state.loginEmail.error = {};
    });
    builder.addCase(doLoginEmail.fulfilled, (state, action) => {
      state.loginEmail.loading = false;
      state.loginEmail.result = action.payload;
      state.loginEmail.error = {};
    });
    builder.addCase(doLoginEmail.rejected, (state, action) => {
      state.loginEmail.loading = false;
      state.loginEmail.error = action.error;
      state.loginEmail.result = {};
    });
    builder.addCase(changePassword.pending, (state) => {
      state.changePassword.loading = true;
      state.changePassword.error = {};
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.changePassword.loading = false;
      state.changePassword.error = {};
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePassword.loading = false;
      state.changePassword.error = action.error;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, getInfo, doLogin, doLoginGoogle, doLoginEmail, changePassword, fetchUsers, searchUsers };
// * Reducer
export const { reducer } = slice;
