import { Spin } from 'antd';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useState } from 'react';

import { Card } from './styles';
import { Props } from './types';

type DataItem = {
  amount: number;
  percent: number;
};

type Data = Record<'normal' | 'abnormal', DataItem>;

const defaultData: Data = {
  normal: {
    amount: 0,
    percent: 0,
  },
  abnormal: {
    amount: 0,
    percent: 0,
  },
};

export const ChartMediaLabeled: React.FC<Props> = ({ transaction, stats, ...props }) => {
  const [data, setData] = useState<Data>(defaultData);

  useEffect(() => {
    if (!stats) return;
    const amountNormal = Number(stats.normal.num_media);
    const amountAbnormal = Number(stats.abnormal.num_media);
    const amountLabeled = amountNormal + amountAbnormal;
    setData({
      normal: {
        amount: amountNormal,
        percent: (100 * amountNormal) / amountLabeled,
      },
      abnormal: {
        amount: amountAbnormal,
        percent: (100 * amountAbnormal) / amountLabeled,
      },
    });
  }, [stats]);

  return (
    <Card>
      {transaction.loading ? (
        <Spin />
      ) : (
        <ReactECharts
          {...props}
          style={{
            width: '100%',
            height: '100%',
          }}
          option={
            {
              title: {
                text: 'Labeled media',
                left: 'center',
                bottom: '23px',
              },
              tooltip: {
                trigger: 'item',
              },
              xAxis: {
                type: 'category',
                data: ['Abnormal', 'Normal'],
              },
              yAxis: {
                type: 'value',
                width: 88,
                max: 100,
                axisLabel: {
                  formatter: '{value} %',
                },
              },
              color: ['#F48FB1', '#F3E5F5'],
              series: [
                {
                  data: [
                    {
                      value: data.abnormal.percent,
                      itemStyle: {
                        borderRadius: 4,
                        color: '#F48FB1',
                      },
                    },
                    {
                      value: data.normal.percent,
                      itemStyle: {
                        borderRadius: 4,
                        color: '#F3E5F5',
                      },
                    },
                  ],
                  type: 'bar',
                  barWidth: 88,
                },
              ],
            } as EChartsOption
          }
        />
      )}
    </Card>
  );
};
