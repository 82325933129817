import './assets/styles/app.scss';
import '@helpers/i18n';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { msalConfig } from '@constants';
import { store, defaultTheme } from '@store';

// import reportWebVitals from './reportWebVitals';
import App from './App';

const themes = {
  dark: `${process.env.PUBLIC_URL}/themes/main-dark.css`,
  light: `${process.env.PUBLIC_URL}/themes/main-light.css`,
};
const msalInstance = new PublicClientApplication(msalConfig);

const container: HTMLElement | null = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <Provider store={store}>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme} insertionPoint={document.getElementById('inject-styles-here')}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </GoogleOAuthProvider>
      </ThemeSwitcherProvider>
    </Provider>
  );
}
// serviceWorkerRegistration.register();
// reportWebVitals();
