import { createAsyncThunk } from '@reduxjs/toolkit';

import { buildUrl } from '@helpers/utils';
import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { ActionParams, ClassificationType, MediaAnnotationItem, MediaModel, MediaStatModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'medias/fetch',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/media/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'medias/fetchMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ dataset_id?: string; project_id?: string; status?: string }>, any>) => {
    const endpoint = buildUrl(`/media`, payload.params);
    const response = await api.caller.get<Response<Pagination<MediaModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchManySample = createAsyncThunk(
  'medias/fetchManySample',
  createAsyncAction(async (payload: ActionParams<Queries<{ anomaly_id?: string; project_id?: string; status?: 'sample' }>, any>) => {
    const endpoint = buildUrl(`/media`, payload.params);
    const response = await api.caller.get<Response<Pagination<MediaModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMediaAnomalyStat = createAsyncThunk(
  'medias/fetchMediaAnomalyStat',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id?: string }>, any>) => {
    const endpoint = buildUrl('/media/anomaly/statictic', payload.params);
    const response = await api.caller.get<Response<MediaStatModel>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'medias/create',
  createAsyncAction(async (payload: ActionParams<{ data: FormData; datasetId: string; controller: AbortController }, any>) => {
    const endpoint = buildUrl(`/media`, { dataset_id: payload.params?.datasetId });
    const response = await api.caller.post(endpoint, payload.params?.data, { signal: payload.params?.controller.signal });
    const data = response.data;

    return data;
  })
);

export const createSample = createAsyncThunk(
  'medias/createSample',
  createAsyncAction(async (payload: ActionParams<{ data: FormData; anomaly_id: string; controller: AbortController }, any>) => {
    const endpoint = buildUrl(`/media/sample`, { anomaly_id: payload.params?.anomaly_id });
    const response = await api.caller.post(endpoint, payload.params?.data, { signal: payload.params?.controller.signal });
    const data = response.data;

    return data;
  })
);

export const labeling = createAsyncThunk(
  'medias/labeling',
  createAsyncAction(async (payload: ActionParams<{ id: string; anomaly?: ClassificationType }, any>) => {
    const endpoint = `/media/${payload.params?.id}/labeling`;
    const response = await api.caller.patch(endpoint, { anomaly: payload.params?.anomaly });
    const data = response.data;

    return data;
  })
);

export const annotate = createAsyncThunk(
  'medias/annotate',
  createAsyncAction(async (payload: ActionParams<{ id: string; annotations: MediaAnnotationItem[] }, any>) => {
    const endpoint = `/media/${payload.params?.id}/annotate`;
    const response = await api.caller.patch(endpoint, { annotations: payload.params?.annotations });
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'medias/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/media/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'medias/remove',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/media/${payload.params.id}`;
    const response = await api.caller.delete(endpoint, payload.params.id);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'medias/removeMany',
  createAsyncAction(async (payload: ActionParams<{ project_id: string; ids: string[] }>) => {
    const endpoint = `/media?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
