import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { MainLayout } from '../MainLayout';

import { BottomBar, LayoutMaster } from './styles';

const { Content } = Layout;

export const AppLayout: React.FC<PropsWithChildren> = () => (
  <MainLayout>
    <LayoutMaster>
      <Content className="cra-content">
        <Outlet />
      </Content>
      <BottomBar>
        <div>
          <a href="#">Feedback</a>
          <a href="#">Language: English (US)</a>
        </div>
        <div>
          <p>© 2022 FPT Software AI Center</p>
          <a href="#">Privacy</a>
          <a href="#">Terms</a>
        </div>
      </BottomBar>
    </LayoutMaster>
  </MainLayout>
);
