import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { Button, message, notification, Progress } from 'antd';
import styled from 'styled-components';

import { uploadActions, uploadSelector } from '@store';
import { bytesToSize } from '@helpers/utils';

const WrapperNoti = styled.div`
  p {
    margin: 0;
  }
`;

const WrapperFileInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const TextName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const useAppNotiUpload = () => {
  const dispatch = useDispatch<AppDispatch>();
  const progress = useSelector(uploadSelector.select);

  useEffect(() => {
    Object.keys(progress).map((id) => {
      const uploadProgress = progress[id];
      if (uploadProgress.isUploadDone) return;

      const isDownloadDone = uploadProgress.total && uploadProgress.downloaded === uploadProgress.total;
      if (!uploadProgress.isStop && (uploadProgress.downloaded || uploadProgress.total) && uploadProgress.uploadFile) {
        const percent = (uploadProgress.downloaded / uploadProgress.total) * 100;

        notification.open({
          key: `media_upload_${id}`,
          message: 'Upload media',
          placement: 'bottomRight',
          duration: 0,
          closeIcon: (
            <Button
              style={{ padding: 0, height: 'unset' }}
              onClick={() => {
                if (!isDownloadDone) {
                  uploadProgress.stop();
                  dispatch(uploadActions.uploadProgress({ id, progress: { isStop: true } }));
                }
              }}
              type="text"
            >
              <CloseOutlined />
            </Button>
          ),
          description: (
            <WrapperNoti>
              {!isDownloadDone && uploadProgress.uploadFile ? (
                <WrapperFileInfo>
                  <TextName>{uploadProgress.uploadFile.name}</TextName>
                  <p>{bytesToSize(uploadProgress.uploadFile.size)}</p>
                </WrapperFileInfo>
              ) : null}
              <p>{percent.toFixed(1)}% uploaded</p>
              <Progress percent={percent} status={uploadProgress.isError ? 'exception' : 'success'} showInfo={false} />
            </WrapperNoti>
          ),
        });
      }

      if (isDownloadDone) {
        dispatch(uploadActions.uploadProgress({ id, progress: { isUploadDone: true } }));
        message.success({ key: `media_upload_${id}`, content: 'Upload successful.' });
        setTimeout(() => notification.close(`media_upload_${id}`), 2000);
      }
    });
  }, [progress]);
};
