import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { buildUrl } from '@helpers/utils';
import { ActionParams, Pagination, Response, RoleModel } from '@types';
import { createAsyncAction } from '@helpers/thunkWrapper';

export const fetchRole = createAsyncThunk(
  'roles/get_role',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/roles/${payload.params.id}`;
    const response = await api.caller.get<Response<RoleModel>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchRoleByProject = createAsyncThunk(
  'roles/get_role_by_project',
  createAsyncAction(async (payload: ActionParams<{ projectId: string }, any>) => {
    const endpoint = `/roles/self?project_id=${payload.params?.projectId}`;
    const response = await api.caller.get<Response<RoleModel>>(endpoint);
    const data = response.data.data;

    return data;
  })
);

export const fetchRoles = createAsyncThunk(
  'roles/get_roles',
  createAsyncAction(async (payload: ActionParams<any, any>, { getState }) => {
    const endpoint = buildUrl(`/roles`, payload.params);
    const response = await api.caller.get<Response<Pagination<RoleModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const createRole = createAsyncThunk(
  'roles/create_role',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/roles`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const updateRole = createAsyncThunk(
  'roles/update_role',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/roles/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const deleteRole = createAsyncThunk(
  'roles/delete_role',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/roles/${payload.params.id}`;
    const response = await api.caller.delete(endpoint, payload.params.id);
    const data = response.data;

    return data;
  })
);

export const deleteRoles = createAsyncThunk(
  'roles/delete_roles',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/roles?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
