import { AudioHTMLAttributes, DetailedHTMLProps, LegacyRef, SyntheticEvent, useRef } from 'react';

import { useAudio } from '@helpers/hooks';

export const Audio: React.FC<DetailedHTMLProps<AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>> = ({ ...props }) => {
  const refAudio = useRef<HTMLAudioElement>();
  const audioHook = useAudio();

  const onPlay = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    if (props.onPlay) props.onPlay(event);
    if (refAudio.current) audioHook.play(refAudio.current);
  };

  const onPause = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    if (props.onPause) props.onPause(event);
    audioHook.release(refAudio.current);
  };

  const onEnded = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    if (props.onEnded) props.onEnded(event);
    audioHook.release(refAudio.current);
  };

  const onSeeking = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    // eslint-disable-next-line
    const element = event.target as HTMLAudioElement;
  };

  return <audio {...props} ref={refAudio as LegacyRef<HTMLAudioElement>} onPlay={onPlay} onPause={onPause} onEnded={onEnded} onSeeking={onSeeking} />;
};
