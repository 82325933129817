import styled from 'styled-components';

export const Wrapper = styled.div`
  && {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperMenu = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const WrapperAction = styled.div`
  padding: 15px 24px;
  display: flex;
  justify-content: center;
`;

export const TitleProject = styled.p<{ collapsed: boolean }>`
  font-weight: bold;
  line-height: 1.71;
  padding: 0 24px;
  margin: 12px 0 0px;
  display: ${(props) => (props.collapsed ? 'none' : 'block')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
