import './AdminLayout.scss';
import { Layout } from 'antd';
import React, { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import { UILoading, References } from '@components';
import { appActions, appSelector, projectActions, roleActions } from '@store';
import { useCheckAuth } from '@helpers/hooks';

import { MainLayout } from '../MainLayout';

import { Loading, SiderMenu } from './components';
import { SiderLayout, Content, LayoutContent, SiderDrawer } from './styles';

export const AdminLayout: React.FC<PropsWithChildren> = () => {
  useCheckAuth();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);
  const sidebarCollapsed = useSelector(appSelector.selectSidebarCollapsed);
  const sidebarDrawerVisible = useSelector(appSelector.selectSidebarDrawerVisible);
  const [loading, setLoading] = useState(true);

  const onCloseSidebarDrawer = () => {
    dispatch(appActions.setSidebarDrawerVisible(false));
  };

  const fetchInitData = async (_projectId?: string) => {
    let projectId = _projectId;
    if (!projectId) {
      try {
        const AIC_PROJECT_WORKSPACE = localStorage.getItem('AIC_PROJECT_WORKSPACE');
        const currentProject = JSON.parse(AIC_PROJECT_WORKSPACE || '');
        projectId = currentProject.id;
        if (!projectId) throw new Error();
      } catch (error) {
        navigate('/project/list');
      }
    }
    await new Promise((response) =>
      dispatch(
        projectActions.fetchProject({
          params: { id: projectId },
          onSuccess: (res) => res && res.success && localStorage.setItem('AIC_PROJECT_WORKSPACE', JSON.stringify(res.data)),
          onEnd: response,
        })
      )
    );
    if (projectId) {
      await Promise.all([new Promise((response) => (projectId ? dispatch(roleActions.fetchRoles({ params: { project_id: projectId }, onEnd: response })) : response(null))), new Promise((response) => (projectId ? dispatch(roleActions.fetchRoleByProject({ params: { projectId }, onEnd: response })) : response(null)))]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData(params.projectId as any);
  }, [params.projectId]);

  if (loading) return <UILoading />;

  return (
    <MainLayout>
      <Layout className="cra-main-layout">
        <SiderDrawer className="sider-drawer" closable={false} visible={sidebarDrawerVisible} placement="left" width={320} onClose={onCloseSidebarDrawer}>
          <SiderMenu collapsible={false} />
        </SiderDrawer>
        <SiderLayout className="sider-layout cra-main-sider" trigger={null} collapsible collapsed={sidebarCollapsed} width={260}>
          <SiderMenu />
        </SiderLayout>
        <LayoutContent className="cra-main-layout-content">
          <Content className="cra-content">
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
            <References />
          </Content>
        </LayoutContent>
      </Layout>
    </MainLayout>
  );
};
