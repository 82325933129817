import React from 'react';
import { Form, Modal, ModalProps } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChangePasswordContent, ChangePasswordFormType } from '@contents';
import { userSelector } from '@store';

export type Props = ModalProps & { setVisible: (value: boolean) => void };

export const ChangePasswordModal: React.FC<Props> = ({ ...props }) => {
  const [form] = Form.useForm<ChangePasswordFormType>();
  const transaction = useSelector(userSelector.selectChangePassword);
  const navigate = useNavigate();

  const onInvisible = () => {
    props.setVisible(false);
    form.resetFields();
  };

  const onOk = async () => form.submit();

  const onCancel = () => onInvisible();

  const handle = async () => {
    navigate('/logout');
  };

  return (
    <Modal {...props} title="Change password" onCancel={onCancel} onOk={onOk} okButtonProps={{ htmlType: 'submit', form: 'change-password', disabled: transaction.loading }} confirmLoading={transaction.loading} centered destroyOnClose>
      <ChangePasswordContent form={form} handle={handle} button={{ visible: false }} />
    </Modal>
  );
};
