import styled from 'styled-components';
import { Layout } from 'antd';

export const LayoutMaster = styled(Layout)`
  height: 100vh;
  background: #fff;
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px 24px;

  & > div {
    & > * {
      color: ${({ theme }) => theme.colors.text.main};
      display: inline-block;
      margin: 0;

      font-size: 0.86rem;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.4px;
    }
    & > *:not(:last-child) {
      margin-right: 24px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    flex-direction: column;

    & > div {
      & > *:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
`;
