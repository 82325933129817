import { useRef, useState } from 'react';

import { ModalConfirm } from '@components';

type Props =
  | {
      title?: string;
      message?: string;
    }
  | undefined;

export const useCheckRedirect = (props?: Props) => {
  const [showModal, setShowModal] = useState(false);
  const refBlock = useRef<boolean>(false);
  const refRedirect = useRef<Promise<void>>(new Promise((res) => res()));
  const refRedirectConfirm = useRef<() => unknown>();

  const block = () => {
    refBlock.current = true;
  };

  const approve = () => {
    if (refRedirectConfirm.current) {
      refRedirectConfirm.current();
      refRedirectConfirm.current = undefined;
    }
    refBlock.current = false;
  };

  const check = () => {
    if (refBlock.current) {
      refRedirect.current = new Promise((res) => (refRedirectConfirm.current = res));
      setShowModal(true);
    }
    return refRedirect.current;
  };

  const onClickCancel = () => {
    refRedirect.current = new Promise((res) => res());
    refRedirectConfirm.current = undefined;
    setShowModal(false);
  };

  const renderModal = () => (
    <ModalConfirm title={props?.title || 'Notice'} visible={showModal} setVisible={setShowModal} onClickOk={approve} onCancel={onClickCancel} confirmLoading={false} okType="danger">
      {props?.message || 'There are unsaved changes, do you wish to discard them'}
    </ModalConfirm>
  );

  return {
    approve,
    block,
    check,
    renderModal,
  };
};
