import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.main.primary};
  text-align: center;
  color: #fff;
  padding: 2px 0;
`;

export const Alert: React.FC<{ msg: string }> = ({ msg }) => <Wrapper>{msg}</Wrapper>;
