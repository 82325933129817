import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { buildUrl } from '@helpers/utils';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { ActionParams, Pagination, ProjectModel, Response } from '@types';
import { WorkflowKeys } from '@constants';

export const fetchProject = createAsyncThunk(
  'projects/get_project',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/projects/${payload.params.id}`;
    const response = await api.caller.get<Response<ProjectModel>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchProjects = createAsyncThunk(
  'projects/get_projects',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`/projects`, payload.params);
    const response = await api.caller.get<Response<Pagination<ProjectModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const createProject = createAsyncThunk(
  'projects/create_project',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/projects`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const updateProject = createAsyncThunk(
  'projects/update_project',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/projects/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const updateWorkflow = createAsyncThunk(
  'projects/update_workflow',
  createAsyncAction(async (payload: ActionParams<{ id: string; step: WorkflowKeys[] }, any>, { getState }) => {
    const state = (getState() as RootState).project.item;
    if (payload.params?.step.some((o) => state.result?.workflow.some((oo) => oo === o))) return;

    const endpoint = `/projects/workflow/${payload.params?.id}`;
    const response = await api.caller.put(endpoint, { workflow: payload.params?.step });
    const data = response.data;

    return data;
  })
);

export const deleteProject = createAsyncThunk(
  'projects/delete_project',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/projects/${payload.params.id}`;
    const response = await api.caller.delete(endpoint, payload.params.id);
    const data = response.data;

    return data;
  })
);
