import './Loading.scss';
import { Spin } from 'antd';
import styled from 'styled-components';

// import internal libs

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const UILoading = () => (
  <Wrapper>
    <Spin size="large" />
  </Wrapper>
);
