import { DefaultTheme } from 'styled-components';

import { Severity } from '@types';

export const camelize = (string: string): string =>
  string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case 'USD': {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const hexToRGB = (hex: string, alpha: string | number): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const getDifference = (value: number, prevValue: number): string | null => (prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%');

export const normalizeProp = (prop: string | number | [number, number]): string => (typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString());

export const defineColorBySeverity = (severity: Severity | undefined, theme: DefaultTheme): string => {
  switch (severity) {
    case 'error':
      return theme.colors.main.error;
    case 'warning':
      return theme.colors.main.warning;
    case 'success':
      return theme.colors.main.success;
    case 'info':
      return theme.colors.main.primary;
    default:
      return theme.colors.main.primary;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] => a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (): { h: number; s: number; l: number } =>
  // const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  // if (result) {
  //   let r = parseInt(result[1], 16);
  //   let g = parseInt(result[2], 16);
  //   let b = parseInt(result[3], 16);
  //   // (r /= 255), (g /= 255), (b /= 255);
  //   const max = Math.max(r, g, b),
  //     min = Math.min(r, g, b);
  //   let h, s;
  //   const l = (max + min) / 2;
  //   if (max == min) {
  //     h = s = 0; // achromatic
  //   } else {
  //     const d = max - min;
  //     s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  //     switch (max) {
  //       case r:
  //         h = (g - b) / d + (g < b ? 6 : 0);
  //         break;
  //       case g:
  //         h = (b - r) / d + 2;
  //         break;
  //       case b:
  //         h = (r - g) / d + 4;
  //         break;
  //       default:
  //         h = 0;
  //     }
  //     h /= 6;
  //   }
  //   return {
  //     h,
  //     s,
  //     l,
  //   };
  // } else {
  //   throw new Error('Non valid HEX color');
  // }

  ({ h: 13, s: 133, l: 122 });
