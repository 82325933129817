import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { config } from './config';

const WrapMenu = styled.div`
  font-weight: 500;

  &,
  & .ant-menu,
  && .ant-menu-item {
    height: 100%;
  }
  & .ant-menu {
    display: flex;
    border-right: none;
  }
  && .ant-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    display: none;
  } */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    && {
      margin-left: auto;
    }
    && .ant-menu-item {
      padding: 0 8px;
      &:last-child {
        margin-right: 8px;
      }
    }
  }
`;

const Text = styled.p`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    display: none;
  }
`;

const Icon = styled.div<{ size?: number }>`
  display: flex;

  svg {
    width: 20px !important;
    height: 20px !important;
    &,
    path {
      stroke: ${({ theme }) => theme.colors.text.main} !important;
      fill: ${({ theme }) => theme.colors.text.main} !important;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const TopMenu: React.FC = () => {
  const location = useLocation();
  const [selectKey, setSelectKey] = useState<string>('');

  useEffect(() => {
    const cf = config.find((o) => location.pathname.includes(o.key));
    setSelectKey(cf?.key || '');
  }, [location]);

  return (
    <WrapMenu>
      <Menu className="header-menu" selectedKeys={[selectKey]}>
        {config.map((o) => (
          <Menu.Item key={o.key}>
            <Link to={o.key}>
              <Text>{o.title}</Text>
              <Icon>{o.icon}</Icon>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </WrapMenu>
  );
};
