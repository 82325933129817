import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Table as UITable, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import type { ColumnsType, ColumnType } from 'antd/es/table';

import { Pagination, Queries } from '@types';
import { PaginationPrevSvg, PaginationNextSvg } from '@assets/svgs';

type Props<RecordType> = { loading: boolean; columns: ColumnsType<RecordType>; data: Pagination<RecordType>; selectedRowKeys?: React.Key[]; defaultQueries?: Record<string, unknown>; setSelectedRowKeys?: (keys: React.Key[]) => void; onUpdateQueries: (queries: Queries) => void } & TableProps<RecordType>;

export const Table = <RecordType extends object>({ data, columns, loading, defaultQueries, selectedRowKeys, setSelectedRowKeys, onUpdateQueries, ...props }: Props<RecordType>) => {
  const { items, pagination } = data;
  const search: Queries = queryString.parse(location.search);
  const sortDefault: ColumnType<RecordType> | undefined = columns.find((o) => o.defaultSortOrder) || columns.find((o) => o.sorter);
  const sortBy = search.sortby || (sortDefault?.dataIndex as string) || '';
  const sorttype = search.sorttype || (sortDefault?.defaultSortOrder === 'ascend' ? 'ascending' : 'descending');
  const defaultQueriesData: Queries = { page: search.page || 1, size: search.size || 10, sortby: sortBy, sorttype: sorttype, ...defaultQueries };
  if (search.filterby) defaultQueriesData.filterby = search.filterby;
  if (search.filtervalue) defaultQueriesData.filtervalue = search.filtervalue;
  const [queries, setQueries] = useState<Queries>(defaultQueriesData);
  const [columnData, setColumnData] = useState<ColumnsType<RecordType> & ColumnsType<RecordType>>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys && setSelectedRowKeys(newSelectedRowKeys);

  const updateQueries = (params: Partial<Record<keyof Queries, any>>) => setQueries((state) => ({ ...state, ...params }));

  const onChange: TableProps<RecordType>['onChange'] = (pag, filters, sorter) => {
    // * Sort
    const sortData = sorter as SorterResult<RecordType>;

    // * Queries
    const nqueries: Queries = {
      page: pag.current,
      size: pag.pageSize,
      sortby: (sorter as SorterResult<RecordType>).field as string,
      sorttype: sortData.order === 'ascend' ? 'ascending' : 'descending',
    };

    // * Filter
    if (filters && Object.keys(filters).length) {
      const filterBy = Object.keys(filters).find((o) => o !== queries.filterby && filters[o]) || queries.filterby;
      const filterValue = filters[filterBy || '']?.[0] as string;
      if (filterBy && filterValue) {
        nqueries.filterby = filterBy;
        nqueries.filtervalue = filterValue;
      }
    }

    setQueries({ ...defaultQueries, ...nqueries });
  };

  useEffect(() => {
    if (!defaultQueries) return;
    const keys = Object.keys(defaultQueries);
    if (keys.some((key) => defaultQueries[key] !== queries[key as keyof Queries])) updateQueries(defaultQueries);
  }, [defaultQueries]);

  useEffect(() => {
    const nsearch = {};
    // eslint-disable-next-line
    // @ts-ignore
    Object.keys(queries).forEach((key) => (nsearch[key] = queries[key]));
    const url = `${window.location.pathname}?${queryString.stringify(nsearch)}`;
    window.history.replaceState({}, '', url);

    onUpdateQueries(queries);
  }, [queries]);

  useEffect(() => {
    if (!queries || !Object.keys(queries).length) return;
    setColumnData(
      columns.map((o) => {
        const no = { ...o };
        if ((no as ColumnType<RecordType>).dataIndex === queries.sortby) no.sortOrder = queries.sorttype === 'ascending' ? 'ascend' : 'descend';
        if ((no as ColumnType<RecordType>).key === queries.filterby) no.filteredValue = [queries.filtervalue as any];
        return no;
      })
    );
  }, [queries, columns]);

  return (
    <UITable
      {...props}
      className="table-svg-icon"
      columns={columnData}
      dataSource={items}
      pagination={{
        current: queries.page && Number(queries.page),
        total: pagination.totalRecords,
        pageSize: queries.size && Number(queries.size),
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30],
        size: 'small',
        prevIcon: <PaginationPrevSvg />,
        nextIcon: <PaginationNextSvg />,
      }}
      loading={loading}
      rowSelection={selectedRowKeys ? { selectedRowKeys, onChange: onSelectChange } : undefined}
      onChange={onChange}
      sortDirections={['descend', 'ascend', 'descend']}
      scroll={{ x: true }}
    />
  );
};
