import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { enLang, viLang } from '@assets/translations';

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);
  const resources = {
    en: {
      translation: enLang,
    },
    vi: {
      translation: viLang,
    },
  };
  i18n.use(HttpApi).init({
    resources,
    lng: 'vi',
  });
  return i18n;
};

export const i18n = createI18n();
