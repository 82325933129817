import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginRequest } from '@constants';
import { userActions, userSelector } from '@store';

import { Button } from '../styles';

export const useAzure = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const transaction = useSelector(userSelector.selectLogin);

  const isAuthenticated = useIsAuthenticated();

  const onLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((loginResponse) => {
        // eslint-disable-next-line
        console.log('loginresonse', loginResponse);
      })
      .catch((e: any) => {
        // eslint-disable-next-line
        console.error(e);
      });
  };

  const renderNotLoggedIn = () => (
    <Button
      type="primary"
      block
      onClick={() => {
        onLogin();
      }}
    >
      Sign in with Fsoft account
    </Button>
  );

  useEffect(() => {
    if (isAuthenticated) dispatch(userActions.doLogin({ params: { instance, accounts } }));
  }, [isAuthenticated]);

  return {
    render: renderNotLoggedIn,
    loading: transaction.loading,
  };
};
