import { useRef, useState } from 'react';

import { OptionData, Pagination, Response } from '@types';
import { PAGE_SIZE } from '@constants';

type OnDropdownVisibleChange = (open: boolean) => void;
type OnScrollType = React.UIEventHandler<HTMLElement>;
export type UseSelectFetch<RecordType> = (params: { page: number; size: number; search: Record<string, unknown> }) => Promise<Response<Pagination<RecordType>>>;
type Props<RecordType> = {
  fetch: UseSelectFetch<RecordType>;
};

export const useSelect = <RecordType>(props: Props<RecordType>) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionData[]>([]);
  const refSearch = useRef<string>();
  const refOptions = useRef<OptionData[]>([]);
  const refTotalRecord = useRef<number>();
  const timeoutSearch = useRef<NodeJS.Timeout>();

  const handleSetOptions = (noptions: OptionData[]) => (state: OptionData[]) => {
    const opt = [...state, ...noptions];
    refOptions.current = opt;
    return opt;
  };

  const reset = () => {
    setOptions(handleSetOptions([])([]));
    refTotalRecord.current = undefined;
  };

  const checkReleaseTimeOut = () => {
    if (timeoutSearch.current) {
      clearTimeout(timeoutSearch.current);
      timeoutSearch.current = undefined;
    }
  };

  const fetchData = async (isSearch?: boolean) => {
    try {
      if (refTotalRecord.current && refOptions.current.length >= refTotalRecord.current) return;

      setLoading(true);

      const searchObject = Boolean(refSearch.current) ? { searchby: 'name', searchvalue: refSearch.current } : {};

      const response: Response<Pagination<RecordType>> = await props.fetch({ page: Math.floor(refOptions.current.length / PAGE_SIZE) + 1, size: PAGE_SIZE, search: searchObject });

      const noptions: OptionData[] = response.data.items.map((o: any) => ({ value: o.id, label: o.name }));
      refTotalRecord.current = response.data.pagination.totalRecords;
      setOptions((state) => handleSetOptions(noptions)(isSearch ? [] : state));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onDropdownVisibleChange: OnDropdownVisibleChange = (open) => {
    if (!open || loading) return;
    reset();
    fetchData(true);
  };

  const onPopupScroll: OnScrollType = (event) => {
    const element = event.target as HTMLElement;
    if (!loading && element.scrollTop + element.offsetHeight === element.scrollHeight) {
      element.scrollTo(0, element.scrollHeight);
      fetchData();
    }
  };

  const onSearch = (params: string) => {
    checkReleaseTimeOut();
    timeoutSearch.current = setTimeout(() => {
      refSearch.current = params;
      reset();
      fetchData(true);
      checkReleaseTimeOut();
    }, 500);
  };

  return { options, loading, filterOption: false, virtual: false, onPopupScroll, onSearch, onDropdownVisibleChange, fetchData };
};
