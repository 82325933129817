import { Spin } from 'antd';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useState } from 'react';

import { Card } from './styles';
import { Props } from './types';

type DataItem = {
  amount: number;
  percent: number;
};

type Data = Record<'labeled' | 'unlabeled', DataItem>;

const defaultData: Data = {
  labeled: {
    amount: 0,
    percent: 0,
  },
  unlabeled: {
    amount: 0,
    percent: 0,
  },
};

export const ChartMediaStatus: React.FC<Props> = ({ transaction, stats, ...props }) => {
  const [data, setData] = useState<Data>(defaultData);

  useEffect(() => {
    if (!stats) return;
    const total = Number(stats.overall.num_media);
    const amountLabeled = Number(stats.normal.num_media) + Number(stats.abnormal.num_media);
    setData({
      labeled: {
        amount: amountLabeled,
        percent: (100 * amountLabeled) / total,
      },
      unlabeled: {
        amount: total - amountLabeled,
        percent: (100 * (total - amountLabeled)) / total,
      },
    });
  }, [stats]);

  return (
    <Card>
      {transaction.loading ? (
        <Spin />
      ) : (
        <ReactECharts
          {...props}
          style={{
            width: '100%',
            height: '100%',
          }}
          option={
            {
              title: {
                text: 'Media status',
                left: 'center',
                bottom: '23px',
              },
              tooltip: {
                trigger: 'item',
              },
              legend: {
                orient: 'vertical',
                bottom: '150px',
                left: 'center',
              },
              color: ['#F8BBD0', '#F3E5F5'],
              series: [
                {
                  name: 'Access From',
                  type: 'pie',
                  radius: '125px',
                  center: ['50%', '35%'],
                  data: [
                    { value: data.labeled.amount, name: `Labeled\t${data.labeled.amount} (${data.labeled.percent.toFixed(2)}%)` },
                    { value: data.unlabeled.amount, name: `Unlabeled\t${data.unlabeled.amount} (${data.unlabeled.percent.toFixed(2)}%)` },
                  ],
                  label: {
                    show: false,
                    position: 'center',
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                },
              ],
            } as EChartsOption
          }
        />
      )}
    </Card>
  );
};
