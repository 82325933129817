import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Drawer as UIDraw, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { uniqueId } from 'lodash';

import { PlusSvg, RecycleSvg } from '@assets/svgs';

import { BtnDelete, ButtonAdd, ItemList, TextDescription, WrapActions, WrapList } from './styles';
import { ItemProps, Props, PropsConfig } from './types';

const SortableItem = SortableElement(<RecordType extends object>({ value, configTable, configTableFields, onChangeInput, onDeleteColumn, columnIndex }: { value: ColumnType<RecordType>; columnIndex: number } & PropsConfig<RecordType> & ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => setIsOpen((state) => !state);

  const onBlur = () => setIsOpen(false);

  const onChange = (key: string) => {
    setIsOpen(false);
    onChangeInput(columnIndex, key);
  };

  const onClickDelete = () => {
    onDeleteColumn(columnIndex);
  };

  return (
    <ItemList className="sortableHelper">
      <Select value={value.key as string} open={isOpen} onClick={onClick} onBlur={onBlur} onChange={onChange} showSearch>
        {configTableFields
          .filter((o) => o.key === value.key || configTable.every((oo) => oo.key !== o.key))
          .map((o) => (
            <Select.Option key={o.key as string} value={o.key}>
              {o.title as string}
            </Select.Option>
          ))}
      </Select>
      <BtnDelete disabled={configTable.length <= 1} onClick={onClickDelete}>
        <RecycleSvg />
      </BtnDelete>
    </ItemList>
  );
});

const SortableList = SortableContainer(<RecordType extends object>({ items, ...props }: { items: ColumnsType<RecordType> } & PropsConfig<RecordType> & ItemProps) => (
  <WrapList>
    {items.map((value, index: number) => (
      // eslint-disable-next-line
      // @ts-ignore
      <SortableItem key={(value as ColumnType<RecordType>).key || uniqueId()} index={index} columnIndex={index} value={value} {...props} />
    ))}
  </WrapList>
));

export const DrawerCustomColumn = <RecordType extends object>({ configTable, configTableFields, setConfigTable, hideDrawer, ...props }: Props<RecordType>) => {
  const [configTableTemp, setConfigTableTemp] = useState(configTable);
  const [refresh, setRefresh] = useState(false);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) =>
    setConfigTableTemp((state) => {
      const array = state.slice();
      const temp = array[oldIndex];
      array[oldIndex] = array[newIndex];
      array[newIndex] = temp;
      return array;
    });

  const onChangeInput = (columnIndex: number, key: string) => {
    setConfigTableTemp((state) => {
      const fieldChangeData = configTableFields.find((o) => o.key === key);

      if (columnIndex !== -1 && fieldChangeData) state[columnIndex] = fieldChangeData;

      return state;
    });
    setRefresh((state) => !state);
  };

  const onDeleteColum = (index: number) => {
    setConfigTableTemp((state) => state.filter((_, i) => i !== index));
  };

  const onClickAdd = () => setConfigTableTemp((state) => [...state, {}]);

  const onClickCancel = () => {
    hideDrawer();
  };

  const onClickOK = () => {
    setConfigTable(configTableTemp);
    hideDrawer();
  };

  useEffect(() => {
    // console.log('Refresh');
  }, [refresh]);

  useEffect(() => {
    if (props.visible) setConfigTableTemp(configTable);
  }, [configTable, props.visible]);

  return (
    <UIDraw
      {...props}
      placement="right"
      title="Column options"
      footer={
        <WrapActions>
          <Button type="primary" onClick={onClickOK} disabled={configTableTemp.some((o) => !o.key)}>
            OK
          </Button>
          <Button onClick={onClickCancel}>Cancel</Button>
        </WrapActions>
      }
    >
      <TextDescription>Add or remove columns. To change the column order, drag and drop a field</TextDescription>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <SortableList items={configTableTemp} onSortEnd={onSortEnd} onChangeInput={onChangeInput} configTable={configTableTemp} configTableFields={configTableFields} onDeleteColumn={onDeleteColum} />
      {configTableTemp.length < configTableFields.length ? (
        <ButtonAdd type="dashed" onClick={onClickAdd} icon={<PlusSvg />} className="btn-svg-icon">
          Add a column
        </ButtonAdd>
      ) : null}
    </UIDraw>
  );
};
