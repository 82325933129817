import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import { userActions, userSelector } from '@store';

import { Button } from '../styles';

export const useGoogle = () => {
  const dispatch = useDispatch<AppDispatch>();
  const transaction = useSelector(userSelector.selectLoginGoogle);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (res) => {
      dispatch(userActions.doLoginGoogle({ params: { code: res.code } }));
    },
    onError: () => {
      message.error('Login google failed');
    },
  });

  const render = () => (
    <Button type="primary" block onClick={() => login()}>
      Sign in with Google account
    </Button>
  );

  return { render, loading: transaction.loading };
};
