import { Drawer, Layout } from 'antd';
import styled from 'styled-components';

export const LayoutContent = styled(Layout)`
  height: 100vh;
`;

export const Content = styled(Layout.Content)`
  &&&.cra-content {
    height: 100%;
    overflow-y: scroll;
    padding: 20px 40px 5px 40px;
    background-color: ${(props) => props.theme.colors.main.mainBackground};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
      padding: 10px 15px 5px;
    }
  }
`;

export const SiderLayout = styled(Layout.Sider)`
  height: 100vh;
`;

export const SiderDrawer = styled(Drawer)`
  height: 100vh;
`;
