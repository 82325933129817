import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, ArchitechturesModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'architechtures/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/architectures/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'architechtures/getMany',
  createAsyncAction(async (payload: ActionParams<Queries, any>, { getState }) => {
    const states = getState() as RootState;
    const architect = states.architechtures.list.result;

    if (architect && architect.items.length)
      return {
        data: architect,
        message: '',
        status_code: 200,
        success: true,
      };

    const endpoint = buildUrl(`/architectures`, payload?.params);
    const response = await api.caller.get<Response<Pagination<ArchitechturesModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'architechtures/create',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/architectures`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'architechtures/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/architectures/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'architechtures/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/architectures/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'architechtures/removeMany',
  createAsyncAction(async (payload: ActionParams<{ ids: string[] }>) => {
    const endpoint = `/architectures?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
