import { createSlice } from '@reduxjs/toolkit';

import { fetchRole, fetchRoleByProject, fetchRoles, createRole, updateRole, deleteRole, deleteRoles } from './action';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: { items: [], pagination: { currentPage: 0, totalPages: 0, totalRecords: 0 } },
  },
  item: {
    loading: false,
    result: {},
  },
  delete: {
    loading: false,
  },
  userRole: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRole.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(fetchRole.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(fetchRole.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(fetchRoleByProject.pending, (state) => {
      state.userRole.loading = true;
      state.userRole.error = {};
    });
    builder.addCase(fetchRoleByProject.fulfilled, (state, action) => {
      state.userRole.loading = false;
      state.userRole.result = action.payload;
      state.userRole.error = {};
    });
    builder.addCase(fetchRoleByProject.rejected, (state, action) => {
      state.userRole.loading = false;
      state.userRole.error = action.error;
      state.userRole.result = undefined;
    });
    builder.addCase(fetchRoles.pending, (state) => {
      state.list.loading = true;
      state.list.error = {};
    });
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload.data;
      state.list.error = {};
    });
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
    });
    builder.addCase(createRole.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(createRole.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(createRole.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(updateRole.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(deleteRole.pending, (state) => {
      state.delete.loading = true;
      state.delete.error = {};
    });
    builder.addCase(deleteRole.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = {};
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, fetchRole, fetchRoleByProject, fetchRoles, createRole, updateRole, deleteRole, deleteRoles };
// * Reducer
export const { reducer } = slice;
