import styled from 'styled-components';
import { Layout } from 'antd';

export const LayoutMaster = styled(Layout)`
  height: 100vh;
  main.ant-layout-content.cra-content {
    background-color: white;
  }

  .cra-main-page {
    min-height: 100vh;

    .cra-main-header {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-bottom: 1px solid #dfdfdf;
      height: 56px;
      padding: 0 20px;

      .wrapper-logo {
        width: 10%;

        .logo {
          width: 100px;
        }
      }

      .cra-header-menu-left {
        padding: 0 30px;
        width: 50%;
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 15px;
        }

        .header-menu {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: 0;

          .ant-menu-item {
            .ant-menu-title-content {
              a {
                text-decoration: none;
                color: #333;
              }
            }

            &:hover {
              background-color: #f7f0ff;
              color: ${({ theme }) => theme.colors.main.primary};

              .ant-menu-title-content {
                a {
                  color: ${({ theme }) => theme.colors.main.primary};
                }
              }
            }
          }
        }
      }

      .cra-header-menu-right {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .menu-right {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;

          .nav-menu-right {
            list-style: none;
            margin: 0 10px;

            .anticon {
              color: #333 !important;

              &:hover {
                color: ${({ theme }) => theme.colors.main.primary} !important;
              }
            }
          }
        }
      }

      .ant-menu-item {
        margin: 0;
        height: auto;
        line-height: inherit;
        margin-bottom: 0 !important;
      }

      .ant-menu-item-selected {
        margin: 0;
        height: auto;
        line-height: inherit;
        margin-bottom: 0 !important;
      }
    }

    .cra-main-layout {
      /* margin: 0 auto ;
    margin-left: 20px;
     */

      .cra-main-layout-content {
        padding: 0px;
        padding-top: 68px;
        background: white;

        .cra-content {
          padding: 0 16px 16px 16px;
          margin: 0;
          min-height: 280px;
        }
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #f7f0ff;
      color: ${({ theme }) => theme.colors.main.primary};
    }

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: ${({ theme }) => theme.colors.main.primary} !important;
    }
  }
`;
export const DropMenu = styled.div`
  margin-right: 15px;
`;
