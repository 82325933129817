import { createSlice } from '@reduxjs/toolkit';

import { getDataGovernance } from './action';
import { State } from './type';

const initialState: State = {
  getDataGovernance: {
    loading: false,
  },
};

export const slice = createSlice({
  name: 'architechs',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDataGovernance.pending, (state) => {
      state.getDataGovernance.loading = true;
      state.getDataGovernance.error = {};
    });
    builder.addCase(getDataGovernance.fulfilled, (state) => {
      state.getDataGovernance.loading = false;
      state.getDataGovernance.error = {};
    });
    builder.addCase(getDataGovernance.rejected, (state, action) => {
      state.getDataGovernance.loading = false;
      state.getDataGovernance.error = action.error;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, getDataGovernance };
// * Reducer
export const { reducer } = slice;
