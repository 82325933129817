import { ExportedDatasetModel, PipelineStatus } from '@types';

export const buildUrl = (url: string, parameters: any): string => {
  let qs = '';
  for (const key in parameters) {
    const value = parameters[key];
    if (value !== '') {
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1);
    url = url + '?' + qs;
  }
  return url;
};

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)}${sizes[i]}`;
};

export const getExportedDatasetStatus = (dataset: ExportedDatasetModel['state']) => {
  const { preprocessing, spliting } = dataset;
  const statusArr = [preprocessing.status, spliting.status];
  let status: PipelineStatus = 'Not started';
  if (statusArr.every((oo) => oo === 'Not started')) status = 'Not started';
  else if (statusArr.every((oo) => oo === 'Done')) status = 'Done';
  else if (statusArr.some((oo) => oo === 'Failed' || oo === 'Cancelled')) status = 'Failed';
  else if (statusArr.some((oo) => oo === 'Running') || statusArr.some((oo) => oo === 'Done')) status = 'Running';
  return status;
};

export const getExportedDatasetTitle = (dataset: ExportedDatasetModel['state']) => {
  const { preprocessing, spliting } = dataset;
  const statusArr = [preprocessing.status, spliting.status];
  let status: Omit<PipelineStatus, 'Running'> | 'Preprocessing' | 'Spliting' | '' = 'Not started';
  if (statusArr.every((oo) => oo === 'Not started')) status = 'Not started';
  else if (statusArr.every((oo) => oo === 'Done')) status = 'Done';
  else if (statusArr.some((oo) => oo === 'Failed' || oo === 'Cancelled')) status = 'Failed';
  else if (statusArr.some((oo) => oo === 'Running') || statusArr.some((oo) => oo === 'Done')) {
    if (preprocessing.status === 'Running') status = 'Preprocessing';
    else status = 'Spliting';
  }
  return status;
};
