import { createSlice, createAction, PrepareAction } from '@reduxjs/toolkit';

import { ThemeType } from '@types';

type ThemeState = {
  theme: ThemeType;
};

export const defaultTheme = (localStorage.getItem('theme') as ThemeType) || 'light';

localStorage.setItem('theme', defaultTheme);

const initialState: ThemeState = {
  theme: defaultTheme,
};

export const setTheme = createAction<PrepareAction<ThemeType>>('theme/setTheme', (theme: ThemeType) => {
  localStorage.setItem('theme', theme);
  return {
    payload: theme,
  };
});

export const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTheme, (state, action) => {
      state.theme = action.payload;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, setTheme };
// * Reducer
export const { reducer } = slice;
