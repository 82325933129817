import { Typography } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  isEditable: boolean;
  defaultValue: string;
  value?: string;
  onChangeHandle: (value: string) => Promise<void>;
};

export const TextEditable: React.FC<Props> = ({ isEditable, defaultValue, value, onChangeHandle, ...props }) => {
  const [content, setContent] = useState('');

  const setContentValue = (v?: string) => {
    if (v) setContent(v);
    else setContent(defaultValue);
  };

  const onStartChangeContent = async () => {
    if (!value) setContent('');
  };

  const onEndChangeContent = async () => {
    if (!value) setContent(defaultValue);
  };

  const onChangeContent = async (v: string) => {
    setContentValue(v);
    if (v !== value) await onChangeHandle(v);
  };

  useEffect(() => {
    setContentValue(value);
  }, [value]);

  return (
    <Typography.Paragraph {...props} editable={isEditable ? { onChange: onChangeContent, onStart: onStartChangeContent, onEnd: onEndChangeContent } : undefined}>
      {content}
    </Typography.Paragraph>
  );
};
