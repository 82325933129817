import React from 'react';
import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Text = styled.span`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
`;

const Icons = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: nowrap;
`;

const ReferencesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: ${(props) => `${props.theme.mobileLayout.paddingVertical}`};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    flex-direction: column;
    justify-content: center;
    ${Text}, ${Icons} {
      justify-content: center;
      width: 100%;
      margin: 0;
    }
  }
`;

export function withStyles(Component: any): any {
  return styled(Component)`
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  `;
}

const GithubIcon = withStyles(GithubOutlined);
const TwitterIcon = withStyles(TwitterOutlined);
const FacebookIcon = withStyles(FacebookOutlined);
const LinkedinIcon = withStyles(LinkedinOutlined);

export const References: React.FC = () => (
  <ReferencesWrapper>
    <Text>
      Made by{' '}
      <a href="#" target="_blank" rel="noreferrer">
        FPT-Software{' '}
      </a>
      in 2022 &copy;. Developed by{' '}
      <a href="#" target="_blank" rel="noreferrer">
        FPT Software.
      </a>{' '}
      {process.env.REACT_APP_VERSION}
    </Text>
    <Icons>
      <a href="#" target="_blank" rel="noreferrer">
        <GithubIcon />
      </a>
      <a href="#" target="_blank" rel="noreferrer">
        <TwitterIcon />
      </a>
      <a href="#" target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href="#" target="_blank" rel="noreferrer">
        <LinkedinIcon />
      </a>
    </Icons>
  </ReferencesWrapper>
);
