import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || '',
    authority: process.env.REACT_APP_ADB2C_AUTHORITY || '',
    knownAuthorities: [],
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI || '',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: number, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

export const editProfile = {
  authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY,
};

export const policyNames = {
  signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY,
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
