import { Rule } from 'antd/lib/form';

const validatePassword = (value: string): boolean => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\_@$!%*?&#^])[A-Za-z\d_@$!%*?&#^]{6,20}$/.test(value);

export const passwordRules: Rule[] = [
  { required: true, message: 'Please enter your password!' },
  {
    validator: (_, value) => (validatePassword(value) ? Promise.resolve() : Promise.reject(new Error('Password must be a minimum of 6 characters, including number, upper, lower and one special character!'))),
  },
];

export const cofirmPasswordRules: Rule[] = [
  { required: true, message: 'Please enter your confirmed password!' },
  (({ getFieldValue }) => ({
    validator: (_, value) => (value && getFieldValue('new_password') === value ? Promise.resolve() : Promise.reject(new Error('The two passwords that you confirmed do not match!'))),
  })) as Rule,
];
