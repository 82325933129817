export const RESPONSE_MESSAGE = {
  20000: 'Analyze Success',
  20001: 'Change status successfully',
  20002: 'Get all predictions successfully',
  40008: 'Invalid data input',
  40004: 'Upload file to cloud fail',
  40003: 'Can not connect to model ai',
  40002: 'Invalid file upload (only csv)',
  42200: 'Format file incorrect',
  40000: 'Azalyze fail',
  40401: 'The predictions does not exist with the give id',
  40001: 'Change status failed',
  40402: 'The predictions does not exist',
};
