import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, ClassificationModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'classification/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/anomalies/${payload.params.id}`;
    const response = await api.caller.get<Response<ClassificationModel>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'classification/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id?: string }>, any>) => {
    const endpoint = buildUrl(`/anomalies`, payload?.params);
    const response = await api.caller.get<Response<Pagination<ClassificationModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'classification/create',
  createAsyncAction(async (payload: ActionParams<{ name: string; project_id: string }, any>) => {
    const endpoint = `/anomalies`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'classification/update',
  createAsyncAction(async (payload: ActionParams<{ id: string; description: string }, any>, { dispatch, getState }) => {
    const { id, ...body } = payload.params || {};
    const endpoint = `/anomalies/${payload.params?.id}`;
    const response = await api.caller.put(endpoint, body);
    const data = response.data;

    const projectId = (getState() as RootState).project.item.result?.id;
    dispatch(fetchMany({ params: { project_id: projectId } }));

    return data;
  })
);

export const remove = createAsyncThunk(
  'classification/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/anomalies/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'classification/removeMany',
  createAsyncAction(async (payload: ActionParams<{ project_id: string; ids: string[] }>) => {
    const endpoint = `/anomalies?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
