import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BurgerSvg } from '@assets/svgs';
import { appActions, userSelector } from '@store';
import { Logo } from '@components';

import { TopMenu, DropdownProfile, Alert } from './components';

const Container = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
`;

const Wrapper = styled(Layout.Header)`
  /* position: fixed;
  z-index: 1; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  && {
    border-color: rgba(33, 33, 33, 0.08);
  }

  & > * {
    height: 100%;
  }

  & .cra-header-menu-right {
    & .ant-row,
    & .ant-col {
      height: 100%;
    }
  }

  & .cra-header-menu-right .ant-row .ant-col:last-child {
    padding-right: none !important;
  }
`;

const Burger = styled(BurgerSvg)`
  margin-right: 12px;
  cursor: pointer;
  display: none !important;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    display: block !important;
  }
`;

const WrapperLeft = styled.div`
  &,
  & > * {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    & > *:last-child:not(:first-child) {
      margin-left: 50px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    width: 100%;
  }
`;

const WrapperRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isPackageExpired = useSelector(userSelector.selectIsPackageExpired);
  const isInWorkspace = /^\/workspace/g.test(location.pathname);
  const to = isInWorkspace ? '/workspace' : '/';
  const [alert, setAlert] = useState<string>();

  const onClickBurger = () => dispatch(appActions.setSidebarDrawerVisible(true));

  useEffect(() => {
    if (isPackageExpired) setAlert('The package has expired. Please contact the product owner for support.');
  }, [isPackageExpired]);

  return (
    <Container>
      {Boolean(alert) && alert && <Alert msg={alert} />}
      <Wrapper className="header cra-main-header">
        <WrapperLeft>
          <>{isInWorkspace ? <Burger onClick={onClickBurger} /> : null}</>
          <Link to={to}>
            <Logo />
          </Link>
          <>{isInWorkspace ? <TopMenu /> : null}</>
        </WrapperLeft>
        <WrapperRight>
          <DropdownProfile />
        </WrapperRight>
      </Wrapper>
    </Container>
  );
};
