import React, { PropsWithChildren } from 'react';
import { Dropdown, Typography, Divider } from 'antd';
import styled, { css } from 'styled-components';

const DropdownHeader = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    ${(props) => css`
      @media only screen and ${props.theme.media.md} {
        font-size: 1.625rem;
      }
    `}
  }

  & .ant-badge {
    display: inline-block;
  }
`;

const HeaderIcon = styled.div`
  font-size: 1.43rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    font-size: 1.43rem;
  }
`;

const DropdownMenu = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow.main};
  border-radius: ${(props) => props.theme.border.radius};
  line-height: 1.5715;
`;

export const OverlayMenu = styled(DropdownMenu)`
  width: 13rem;
  background: #fff;

  &.ant-menu {
    box-shadow: ${(props) => props.theme.boxShadow.main};
  }
`;

export const Text = styled(Typography.Text)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.main.primary};

  &,
  & > a {
    cursor: pointer;
    font-weight: 600 !important;
  }

  & > a {
    display: block;
  }

  ${({ theme }) => css`
    @media only screen and ${theme.media.md} {
      font-size: 1rem;
    }
  `}
`;

export const MenuItem = styled.div`
  line-height: 2.8;
  text-align: center;
  a {
    color: ${(props) => props.theme.colors.main.primary};
    font-weight: lighter;
    font-size: 1rem;
  }
`;

export const ItemsDivider = styled(Divider).withConfig({
  shouldForwardProp: (prop) => !['eventKey', 'warnKey'].includes(prop),
})`
  margin: 0;
`;

const Wrapper = styled.div`
  &,
  & .ant-dropdown-trigger,
  & .ant-dropdown-trigger ${HeaderIcon} {
    height: 100%;
  }
`;

type Props = {
  overlay: React.ReactElement;
};

export const DropdownBase: React.FC<PropsWithChildren<Props>> = ({ overlay, children }) => (
  <Wrapper>
    <Dropdown overlay={overlay} trigger={['click']} placement="bottom" overlayClassName="head-dropdown">
      <DropdownHeader>
        <HeaderIcon>{children}</HeaderIcon>
      </DropdownHeader>
    </Dropdown>
  </Wrapper>
);
