import { Button as UIButton } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  padding: 24px;
  min-width: 380px;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    border: none !important;
  }
`;

export const Title = styled.div`
  font-size: 1.71rem;
  line-height: 1;
  letter-spacing: 0.18px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Button = styled(UIButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 81px;
`;
