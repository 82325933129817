import { PlusOutlined } from '@ant-design/icons';
import { Form, FormItemProps, InputRef, Input, Tag, Tooltip } from 'antd';
import { FormInstance } from 'rc-field-form';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const Bouder = styled.div`
  border: 1px solid #d9d9d9;
  padding: 12px 11px;
  border-radius: 4px;
  transition: all 0.3s;
  min-height: 46px;

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-input,
  .edit-tag {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      .edit-tag .anticon-close,
      .ant-tag.site-tag-plus {
        display: none;
      }
      .edit-tag {
        color: rgba(0, 0, 0, 0.25);
      }
    `}

  .ant-form-item.ant-form-item-has-error {
    ${Bouder} {
      border-color: ${({ theme }) => theme.colors.main.error};
    }
  }

  .ant-form-item:not(.ant-form-item-has-error):hover {
    ${Bouder} {
      border-color: ${({ theme }) => theme.colors.main.primary};
    }
  }
`;

type Props = FormItemProps & { form: FormInstance<any>; placeholder: string; disabled?: boolean; onRemove?: (tag: string) => void };

export const InputTags: React.FC<Props> = ({ form, placeholder, disabled, onRemove, ...props }) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    form.setFieldsValue({ [props.name as string]: newTags });
    if (onRemove) onRemove(removedTag);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      form.setFieldsValue({ [props.name as string]: [...tags, inputValue] });
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    form.setFieldsValue({ [props.name as string]: newTags });
    setEditInputIndex(-1);
    setInputValue('');
  };

  useEffect(() => {
    setTags(props.initialValue || []);
  }, [props.initialValue]);

  return (
    <Wrapper disabled={disabled}>
      <Form.Item {...props}>
        <Bouder>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return <Input ref={editInputRef} key={tag} size="small" className="tag-input" value={editInputValue} onChange={handleEditInputChange} onBlur={handleEditInputConfirm} onPressEnter={handleEditInputConfirm} />;
            }

            const isLongTag = tag.length > 20;

            const tagElem = (
              <Tag className="edit-tag" key={tag} closable onClose={() => handleClose(tag)}>
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible && <Input ref={inputRef} type="text" size="small" className="tag-input" value={inputValue} onChange={handleInputChange} onBlur={handleInputConfirm} onPressEnter={handleInputConfirm} />}
          {!inputVisible && (
            <Tag className="site-tag-plus" onClick={showInput}>
              <PlusOutlined /> {placeholder}
            </Tag>
          )}
        </Bouder>
      </Form.Item>
    </Wrapper>
  );
};
