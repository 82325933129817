import { BarSvg, CheckSvg, DashboardSvg, DeploySvg, LightSvg, MonitoringSvg, OverviewSvg } from '@assets/svgs';

import { SidebarNavigationItem } from './type';

export const sidebarNavigationConfig: SidebarNavigationItem[] = [
  {
    title: 'ML workflow',
    key: '/workspace/workflow',
    url: '/workspace/workflow',
    icon: <DashboardSvg />,
  },
  // {
  //   title: 'Overview',
  //   key: '/workspace/overview',
  //   url: '/workspace/overview',
  //   icon: <OverviewSvg />,
  // },
  {
    title: 'Data',
    key: '/workspace/data',
    icon: <BarSvg />,
    children: [
      {
        title: 'Overview',
        key: '/workspace/data/overview',
        url: '/workspace/data/overview',
      },
      {
        title: 'Media',
        key: '/workspace/data/media',
        url: '/workspace/data/media',
      },
      {
        title: 'Sound libraries',
        key: '/workspace/data/sound-libraries',
        url: '/workspace/data/sound-libraries',
      },
      {
        title: 'Label',
        key: '/workspace/data/label',
        url: '/workspace/data/label',
      },
      {
        title: 'Exported dataset',
        key: '/workspace/data/exported-dataset',
        url: '/workspace/data/exported-dataset',
      },
    ],
  },
  // {
  //   title: 'Models',
  //   key: '/workspace/models',
  //   icon: <LightSvg />,
  //   children: [
  //     {
  //       title: 'Pipeline',
  //       key: '/workspace/models/pipeline',
  //       url: '/workspace/models/pipeline',
  //     },
  //   ],
  // },
  // {
  //   title: 'Deployment',
  //   key: '/workspace/deployment',
  //   icon: <DeploySvg />,
  //   children: [
  //     {
  //       title: 'Overview',
  //       key: '/workspace/deployment/overview',
  //       url: '/workspace/deployment/overview',
  //     },
  //     {
  //       title: 'Model & Device',
  //       key: '/workspace/deployment/model-device',
  //       url: '/workspace/deployment/model-device',
  //     },
  //   ],
  // },
  // {
  //   title: 'Monitoring',
  //   key: '/workspace/monitoring',
  //   url: '/workspace/monitoring',
  //   icon: <MonitoringSvg />,
  // },
  {
    title: 'Sample check',
    key: '/workspace/sample-check',
    icon: <CheckSvg />,
    children: [
      {
        title: 'Check',
        key: '/workspace/sample-check/check',
        url: '/workspace/sample-check/check',
      },
      {
        title: 'History',
        key: '/workspace/sample-check/history',
        url: '/workspace/sample-check/history',
      },
    ],
  },
];
