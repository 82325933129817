import { css } from 'styled-components';

export const customStyle = css`
  h2 {
    font-size: 1.71rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.18px;
    margin-bottom: 0;
  }

  .btn-svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;

      path {
        transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  .table-svg-icon {
    .ant-pagination li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .range-picker {
    & .ant-picker-panels {
      ${({ theme }) => css`
        @media only screen and ${theme.media.xs} and (max-width: ${theme.breakpoints.md - 0.02}px)} {
          display: flex;
          flex-direction: column;
        }
      `}
    }
  }

  .search-dropdown {
    box-shadow: ${({ theme }) => theme.boxShadow.main};

    ${({ theme }) => css`
      @media only screen and ${theme.media.xs} and (max-width: ${theme.breakpoints.md - 0.02}px) {
        width: calc(100vw - 16px);
        max-width: 600px;
      }

      @media only screen and ${theme.media.md} {
        max-width: 323px;
      }
    `}
  }

  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: ${({ theme }) => theme.colors.text.main};
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: ${({ theme }) => theme.colors.text.main};
    font-weight: ${({ theme }) => theme.commonFontWeight.bold};
  }

  .ant-picker svg {
    color: ${({ theme }) => theme.colors.text.light};
  }

  .sortableHelper {
    z-index: 10000 !important;
  }

  .dropdown-label {
    width: 170px !important;
  }
`;
