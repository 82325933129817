import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 508px;
  height: 574px;
  margin-bottom: 16px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    width: 100%;
  }
`;
