import { Form, Input } from 'antd';
import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { userActions, userSelector } from '@store';
import { ChevronLeftSvg } from '@assets/svgs';

import { Button } from '../styles';

type FormType = {
  email: string;
  password: string;
};

const WrapBack = styled.div`
  position: absolute;
  left: 24px;
  top: 20px;

  .ant-btn {
    padding: 0;
    border: 0;
    &,
    &:hover,
    &:focus {
      background: none !important;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    position: fixed;
  }
`;

export const useEmail = () => {
  const [form] = Form.useForm<FormType>();
  const [isShow, setIsShow] = useState(false);
  const transaction = useSelector(userSelector.selectLoginEmail);
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = (data: FormType) => {
    dispatch(userActions.doLoginEmail({ params: data }));
  };

  const render = () => (
    <Button type="primary" block onClick={() => setIsShow(true)}>
      Sign in with Email
    </Button>
  );

  const renderBackButton = () =>
    isShow && (
      <WrapBack>
        <Button type="text" onClick={() => setIsShow(false)} disabled={transaction.loading}>
          <ChevronLeftSvg />
        </Button>
      </WrapBack>
    );

  const renderContent = () => (
    <>
      <Form form={form} name="register" initialValues={{}} onFinish={onFinish} layout="vertical" disabled={transaction.loading}>
        <Form.Item
          name="email"
          className="material-input"
          label="Email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Invalid Email' },
          ]}
        >
          <Input id="email" placeholder="Email" autoFocus maxLength={50} />
        </Form.Item>
        <Form.Item name="password" className="material-input" label="Password" rules={[{ required: true, message: 'Password is required' }]}>
          <Input id="password" placeholder="Password" type="password" autoFocus maxLength={50} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={transaction.loading} onClick={() => setIsShow(true)} block>
          Sign in
        </Button>
      </Form>
    </>
  );

  const renderProvider = (children: ReactNode) => <>{isShow ? renderContent() : children}</>;

  return { render, renderProvider, renderBackButton, loading: transaction.loading };
};
